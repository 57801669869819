export default {
    vendor_code: {
        required: true,
        message: 'Toko tidak boleh kosong!',
    },
    program_id: {
        required: true,
        message: 'Program tidak boleh kosong!',
    },
    uom_id: {
        required: true,
        message: 'Uom tidak boleh kosong!',
    },
    points: {
        required: true,
        message: 'Point tidak boleh kosong!',
    },
    date: {
        required: true,
        message: 'Tanggal tidak boleh kosong!',
    },
    description: {
        required: true,
        message: 'Deskripsi tidak boleh kosong!',
    },
}
