const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Program',
    dataIndex: 'kode_program',
  },
  {
    title: 'Nama Program',
    dataIndex: 'nama_program',
  },
  {
    title: 'Region Program',
    dataIndex: 'region_program',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'kode_toko',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'nama_toko',
  },
  {
    title: 'Area Toko',
    dataIndex: 'area_toko',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region_toko',
  },
  {
    title: 'Poin Masuk',
    dataIndex: 'points',
  },
  {
    title: 'Poin Digunakan',
    dataIndex: 'poin_digunakan',
  },
  {
    title: 'Sisa Poin',
    dataIndex: 'sisa_poin',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
  },
  {
    title: 'Nama Unit',
    dataIndex: 'uoms_name',
  },
  {
    title: 'Tipe Program',
    dataIndex: 'tipe_program',
  },
]
export default acolumns
