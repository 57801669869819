<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Poin Program' : form.id ? 'Edit Poin Program' : 'Tambah Poin Program'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1000px"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Toko"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="vendor_code"
          >
            <filter-toko-register-loyalty
              type="text"
              style="width: 100%"
              v-model:value="vendor_code"
              :disabled="model.readOnly"
              :mode="null"
              required
            ></filter-toko-register-loyalty>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="program_id"
          >
            <filter-program-by-store
              type="text"
              style="width: 100%"
              v-model:value="program_id"
              v-model:toko="vendor_code"
              placeholder="Pilih Program"
              :disabled="model.readOnly"
              :mode="null"
              required
            ></filter-program-by-store>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="UOM"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="uom_id"
          >
            <filter-loyalty-uom-by-vendor
              type="text"
              style="width: 100%"
              v-model:value="uom_id"
              v-model:program="program_id"
              v-model:toko="vendor_code"
              :disabled="model.readOnly"
              :mode="null"
              required
            ></filter-loyalty-uom-by-vendor>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Poin"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="points"
          >
            <a-input
              type="number"
              width="100%"
              v-model:value="points"
              :disabled="model.readOnly"
            />
            <span class="text-danger" v-if="String(points).length > 12 && points != null">Maksimal 12 Karakter</span>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tanggal"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="date"
            >
            <a-date-picker
              class="w-100"
              v-model:value="date"
              format="DD MMMM YYYY"
              placeholder="Date"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="description">
            <ATextarea
              v-model:value="description"
              :disabled="model.readOnly"
              placeholder="Deskripsi" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRef, toRefs, ref, onMounted, watch, methods } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import rules from './rules'
import FilterTokoRegisterLoyalty from '@/components/filter/FilterTokoRegisterLoyalty'
import FilterProgramByStore from '@/components/filter/FilterProgramByStore'
import FilterLoyaltyUomByVendor from '@/components/filter/FilterLoyaltyUomByVendor'
import moment from 'moment'
import { exit } from 'process'

export default defineComponent({
  components: {
    FilterProgramByStore,
    FilterTokoRegisterLoyalty,
    FilterLoyaltyUomByVendor,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        points: null,
        vendor_code: null,
        program_id: null,
        uom_id: null,
        date: null,
        description: null,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))
    const formRef = ref()
    const errorMessage = ref(null)
    const data = ref()
    const q = ref('')
    const state = reactive({
      isFetching: false,
    })

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      const form_data = serialize(
        {
          ..._.omit(form, [
            'date',
          ]),
          date: formatMoment(form.date),
        },
        { indices: true },
      )

      const url = `/api/loyalty-points${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    watch(() => {
      // 
    })

    onMounted(() => {
      fetchData()
    })

    const fetchData = () => {
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    const formatMoment = (value) => {
      return value instanceof moment ? value.format('YYYY-MM-DD') : value
    }

    return {
      form,
      ...toRefs(form),
      emit,
      rules,
      data,
      q,
      fetchData,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      filterOption,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
